.root {
  @apply fixed bg-black bg-opacity-40 flex items-center inset-0 z-50 justify-center;
  backdrop-filter: blur(0.8px);
  -webkit-backdrop-filter: blur(0.8px);
}

.modal {
  @apply bg-primary p-12 border border-accent-2 relative;
}

.modal:focus {
  @apply outline-none;
}

.close {
  @apply hover:text-accent-5 transition ease-in-out duration-150 focus:outline-none absolute right-0 top-0 m-6;
}
